import { render, staticRenderFns } from "./GeneralSettings.vue?vue&type=template&id=34b7a27f&scoped=true"
import script from "./GeneralSettings.vue?vue&type=script&lang=js"
export * from "./GeneralSettings.vue?vue&type=script&lang=js"
import style0 from "./GeneralSettings.vue?vue&type=style&index=0&id=34b7a27f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b7a27f",
  null
  
)

export default component.exports